import React from "react";
import Projo from "../Components/Projo";

function ProjectUpload() {
  return (
    <div className="w-screen flex items-center justify-center">
      <Projo />
    </div>
  );
}

export default ProjectUpload;
